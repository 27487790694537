import React from 'react'



const Dialog = (props) => {

    const onClose = (e) => {

        if (props.onClose){
            props.onClose(e)
        }
    }

    const onAplly = (e) => {


        if (props.onAplly){
            props.onAplly(e)
        }
    }

    if (props.shown) {
        return (
            <div id={'dialog'} onClick={e => onClose(e)}>
                <div className="inner" onClick={e => e.stopPropagation()}>
                    <div className={'header'}>{props.header || 'Notice'}</div>
                    <div className={'body'}>{props.body}</div>
                    <div className={'footer'}>
                        <button className={'close'} onClick={ e => onClose(e) }>{props.close}</button>
                        <button className={'aplly'} onClick={ e => onAplly(e) }>{props.apply}</button>
                    </div>
                </div>
            </div>
        )
    }
    return null
}

export default Dialog