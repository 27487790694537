import React from 'react'
import * as File from '../../services/FileService.js'

export default class SuitableMedia extends React.Component{

    constructor (props) {
        super(props)

        // ソースURLが渡されなかった場合はFileから作成
        const src = props.src || File.createUrl(props.file)

        this.state = {
            file: props.file,
            src: src,
        }
    }

    render() {

        if (File.isImage(this.state.file)) {
            return (
                <img src={this.state.src} />

            )
        } else if(File.isVideo(this.state.file)) {
            return (
                <video src={this.state.src} muted controls />

            )
        } else {
            return (
                <span>invalid file.</span>
            )
        }

    }
}