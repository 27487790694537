import React from 'react'
import { Marker, InfoWindow } from "@react-google-maps/api";
import firebase from "firebase";

export default class MapMarker extends React.Component{

    constructor (props) {
        super(props)

        this.state = { places: props.places}

    }


    componentDidUpdate(prevProps) {

        if (this.props.places !== prevProps.places) {
            this.setState({ places: this.props.places})
        }
    }

    render() {

        if (!this.state.places) return (<></>)

        return (
            <>
                {this.state.places.map(p => {

                    return (
                        <Marker key={p.lat * p.lng}
                            position={{
                                lat: p.lat,
                                lng: p.lng,
                            }}
                        />
                    )
                })}

            </>
        );
    }
}