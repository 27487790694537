import React from 'react'
import ScrollLock, { TouchScrollable } from 'react-scrolllock';

import MapComponent from "../../components/atoms/MapComponent"
import * as ContributionService from "../../services/ContributionService";
import * as StrageService from "../../services/StorageService";
import * as CommentService from "../../services/CommentService";

import * as G from "../../GlobalResources";


import CommentModal from "./CommentModal";
import {Link,} from "react-router-dom";
import {connect} from "react-redux";
import ContributionComments from "./ContributionComments";
import SuitableMedia from "../../components/atoms/SuitableMedia";
import ContributeButton from "../../components/blocks/FrontSite/ContributeButton";
import CommentButton from "./CommentButton";
import DetailInfo from "./DetailInfo";
import ResultImages from "./ResultImages";
import TideInfo from "./TideInfo";


class ContributionView extends React.Component{

    constructor (props) {
        super(props)

        this.state = {
            isReady: false,
            cid: this.props.match.params.id,
            data: {},
            user: {},
            resultImg: [],
            tackleImg: [],
            comments: [],
            commentCount: 0,
            commentModalShown: false,
        }
    }


    async componentDidMount() {

        // ローディング画面を表示
        this.props.dispatch({type: 'SET_LOADING', loading: true})
        // 投稿情報を取得
        await this.getContribution()
        // コメントを取得
        this.getComments()
    }


    async getContribution() {
        const cid = this.state.cid
        const queue = []
        // IDを元に投稿データを取得
        queue.push( ContributionService.get(cid) )
        // 投稿データの画像URLを取得
        queue.push( await StrageService.downloadResultImgs(cid) )
        queue.push( await StrageService.downloadTackleImgs(cid) )
        // 非同期に上記２つの処理を実行
        const res = await Promise.all(queue)

        this.setState({
            isReady: true,
            data: res[0],
            user: res[0].user,
            resultImg: res[1],
            tackleImg: res[2],
        })

    }

    async getComments(){
        const cid = this.state.cid

        const comments = await CommentService.getAllAsArray(cid)
        const commentCount = comments.length
        this.setState({comments: comments, commentCount: commentCount})
    }


    async onCommentSubmit(){
        // コメントリストを更新
        await this.getComments()

        this.props.mainRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
        })
    }

    onCommentButtonClick(){

        this.setState({
            commentModalShown: true,
        })
        // 画面のスクロールをロック
        this.props.dispatch({type: 'SET_SCROLLLOCKED', locked: true})

    }
    onModalClose(){
        this.setState({
            commentModalShown: false,
        })
        // 画面のスクロールをロック
        this.props.dispatch({type: 'SET_SCROLLLOCKED', locked: false})
    }

    renderEditButton() {

        // 必要なデータの取得前なら
        if ( !(this.props.authInfo.user && this.state.data) ){
            return <></>
        }

        // ログインユーザーと投稿IDが同じなら
        if ( this.props.authInfo.user.uid == this.state.data.uid ) {
            return (
                <div className={'edit'}>
                    <Link
                        to={'/contribution-edit/' + this.state.data.id}
                        className={'edit-button'}
                    >投稿を編集</Link>
                </div>
            )
        }
        return <></>



    }

    render() {

        if (!this.state.isReady) {
            return <></>
        }
        this.props.dispatch({type: 'SET_LOADING', loading: false})

        return (
            <div id={'contribution-view'}>

                <article id={'contribution'}>

                    <ResultImages items={this.state.resultImg} />

                    { this.renderEditButton() }

                    <div id={'headline'}>
                        <div className="inner">
                            <div className="date">
                                { ContributionService.getDateString(this.state.data)}
                            </div>
                            <h1 className="title">{this.state.data.title}</h1>
                            <div className="comment-count">{this.state.commentCount}件のコメント</div>
                        </div>
                    </div>

                    <div id="user-info">
                        <div className="inner">
                            <div className="title">
                                <span className={'name'}>{this.state.user.user_name}</span>
                                <small className={'suffix'}>さん</small>
                            </div>
                            <p className="desctiption">

                                {this.state.user.description}
                            </p>
                        </div>
                    </div>

                    <div id="content">
                        <div className="inner">
                            <p className="body">
                                {this.state.data.content}
                            </p>

                            { this.state.data.link && (
                                <div className="link">
                                    <a
                                        href={this.state.data.link }
                                        target="_blank"
                                        className={'btn-secondary'}>
                                        リンクを確認
                                    </a>
                                </div>
                            )}

                        </div>
                    </div>

                    { this.state.data.tide && !isEmpty(this.state.data.tide) && <TideInfo data={this.state.data}/> }

                    <DetailInfo
                        data={this.state.data}
                        tackleImg={this.state.tackleImg}
                        comments={this.state.comments}
                    />

                </article>

                <div className="bottom-control">
                    <div className="buttons">
                        <CommentButton
                            onClick={ e => this.onCommentButtonClick(e) }
                            redirectTo={'/view/' + this.state.cid}/>
                        <ContributeButton redirectTo={'/view/' + this.state.cid}/>
                    </div>
                </div>

                <CommentModal
                    contribution={this.state.data}
                    isShown={this.state.commentModalShown}
                    onModalClose={e => this.onModalClose(e)}
                    onCommentSubmit={ e => this.onCommentSubmit() }
                />
            </div>


        )
    }
}

export default connect((state) => state)(ContributionView)

function isEmpty(obj){
    return !Object.keys(obj).length;
}











