import React, {useEffect, useRef, useState} from 'react'
import DatePicker, { registerLocale } from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"
import ja from 'date-fns/locale/ja';
import axios from "axios";
import axiosJsonpAdapter from "axios-jsonp";

const Today = new Date();
registerLocale('ja', ja);

const DateInput = (props) => {

    const [date, setDate] = useState(props.value)

    const handleChange = (date) => {

        setDate(date)

        if (props.onChange) {
            props.onChange(date)
        }
    }


    return (
        <div className={"form-row"}>
            <label>日付 / 潮汐情報</label>
            <div className="input">
                <DatePicker
                    locale='ja'
                    dateFormat="yyyy/MM/dd"
                    selected={date}
                    onChange={ e => handleChange(e) }
                />
            </div>
        </div>
    )
}

export default DateInput