import React from 'react'

import exifr from 'exifr'
import * as StorageService from '../../services/StorageService.js'
import * as Contribution from '../../services/ContributionService'
import * as File from '../../services/FileService.js'
import * as G from '../../GlobalResources'

import MapComponent from "../../components/atoms/MapComponent"
import FcText from "../../components/atoms/FcText"
import FcTextarea from "../../components/atoms/FcTextarea"
import * as Geocode from '../../services/GeocodeService'
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import ImgThumbnails from "./ImgThumbnails";
import Validate, {getValidation} from "./Validate";
import DateInput from "./DateInput";
import TideInfo from "./TideInfo";

class ContributionCreate extends React.Component {

    // マップを操作するための参照
    mapRef = null
    // file input element
    resultFileInput = null
    tackleFileInput = null

    test = null

    constructor(props) {
        super(props)

        this.state = {
            auth: props.authInfo,

            showMap: false,
            map: {
                checked: false,
                located: false,
                defaultCenter: {lat: 35.69575, lng: 139.77521,},
                markers: [],
            },
            resultFiles: [],
            tackleFiles: [],

            data: {

                title: '',
                content: '',
                link: '',
                tackle_description: '',
                lat: 0,
                lng: 0,
                date: new Date(),
                tide: {},
            },
            validation: getValidation(),

        }
    }

    setDataLatlng(center){
        // 入力データを更新
        const {lat, lng} = center

        const data = this.state.data
        data.lat = lat
        data.lng = lng

        return data
    }

    handleMapLoad(ref) {
        this.mapRef = ref
    }

    handleInputChange(e) {

        // ステートから現在のDataを取得
        const data = this.state.data
        // 必要箇所を変更
        data[e.name] = e.value
        // ステートを更新
        this.setState({ data: data })

    }

    async onSubmit(e) {

        e.preventDefault()

        console.log(this.state.data)

        // バリデーションを実行
        const validation = Validate(this.state.data)
        // validation情報をstateにセット
        this.setState({validation: validation})

        // 入力値にエラーがあれば処理を中止
        if ( validation.hasError ) {
            // マップを表示
            const center = await this.getCurrentPos()
            this.setState({
                showMap: true,
                map: {
                    checked: true,
                    located: false,
                    defaultCenter: center,
                    markers: [],
                },
            })
            // 入力フォームのトップまでスクロール
            this.props.wrapperRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
            return false
        }



        // 新規投稿を作成してIDを取得
        const docId = await Contribution.create(this.state.data, this.state.auth.user)

        // アップロードされた画像を保存
        this.uploadAttachmentFIles(docId)

        this.props.history.push('/map')

    }


    async uploadAttachmentFIles(docId){

        const queue = [];
        let resultFilesIndex = 0;
        this.state.resultFiles.map( img => {

            const i = '/' + ++resultFilesIndex
            queue.push( StorageService.uploadResultImg(img.obj, docId + i) )
        })

        let tackleFilesIndex = 0
        this.state.tackleFiles.map( img => {

            const i = '/' + ++tackleFilesIndex
            queue.push( StorageService.uploadTackleImgs(img.obj, docId + i) )
        })

        // アップロードタスクをReduxに保存
        this.props.dispatch({
            type: 'SET_UPLOAD_TASK' ,
            processing: true,
            queue: queue,
        })

        return Promise.all(queue)
    }

    async onFileChange(e) {

        const inputName = e.target.name
        const file = e.target.files[0]

        // ファイルが設定されていなければ処理を中止
        if (!file) return

        const url = File.createUrl(file)

        // 画像か動画ファイルでない場合はエラー
        if( !File.isImageOrVideo(file) ){
            alert('選択されたファイルが不正な形式です。')
            return false
            // throw new Error('the file is not image or video.')
        }

        // 現在の画像ファイル配列を取得
        const imgs = this.state[inputName]
        // 新たにファイルを追加
        imgs.push({obj: file, url: url})
        // ファイルを追加した配列をステートにセット
        this.setState({ [inputName]: imgs })

        // 変更されたInputが釣果ファイル && イメージファイルである && 位置情報の取得前なら
        if (inputName === 'resultFiles' && File.isImageOrVideo(file) && !this.state.map.located) {

            // 画像のExifから位置情報を取得しマップ表示を変更
            this.ajustMapCenterToImageLocation(file)
        }

    }

    async ajustMapCenterToImageLocation(file){

        // 動画ファイルだった場合はチェックを中止してReturn
        if ( File.isVideo(file) ) {
            this.onLocationCheckFailed()
            return false
        }

        // exifから位置情報を取得
        const latlng = await exifr.parse(file)

        // 画像から位置情報が取得できた場合
        if (latlng){

            const {latitude, longitude} = latlng

            const center = {
                lat: latitude,
                lng: longitude,
            }

            this.setState({
                showMap: true,
                map: {
                    checked: true,
                    located: true,
                    defaultCenter: center,
                    markers: [center],
                },
                data: this.setDataLatlng(center),

            })
            console.log('Exif取得 成功')

            return latlng

        //  位置情報の取得が失敗した場合
        } else {

            this.onLocationCheckFailed()
            return false
        }
    }

    async onLocationCheckFailed(){

        // 下記getCrrentPosがエラーになった場合を考慮して先にマップを表示
        this.setState({showMap: true})

        // 初回のチェック失敗時はメッセージを表示
        if (!this.state.map.checked) {
            // const mess = 'マップ上をクリックして位置を調整できます。'

            console.log('Exif取得 失敗')
            const mess = '位置情報の取得に失敗しました。マップ上をクリックして位置を調整してください。'

            this.props.dispatch({type: 'SET_ALERT' , shown: true, body: mess})
            // alert('画像から位置情報の取得に失敗しました。再度画像を選択していただくか、マップ上の場所を手動でご選択ください。')

        }

        const center = await Geocode.getCurrentPos()

        this.setState({
            map: {
                checked: true,
                located: false,
                defaultCenter: center,
                markers: [center],
            },
        })

    }

    handleMapClick(e) {

        const center = {

            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        }

        this.setState({
            map: {
                located: true,
                defaultCenter: center,
                markers: [center],
            },
            data: this.setDataLatlng(center)
        })

    }

    async getCurrentPos(){

        const pos = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        return {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
        }
    }

    checkSavedLocation(e){

        e.preventDefault()

        const cookie = document.cookie
            .split('; ')
            .find(row => row.startsWith('tmpspot'))

        const place = cookie && cookie.split('=')[1];

        // cookieから保存した位置が特定できた場合
        if (place) {
            const latlng = place.split(',')

            const center = {
                lat: parseFloat( latlng[0] ),
                lng: parseFloat( latlng[1] ),
            }

            this.setState({
                showMap: true,
                map: {
                    checked: true,
                    located: true,
                    defaultCenter: center,
                    markers: [center],
                },
                data: this.setDataLatlng(center),
            })
            const mess = '保存した位置にマーカーを設定しました。'
            this.props.dispatch({type: 'SET_ALERT' , shown: true, body: mess})

        // 保存した位置情報の取得に失敗した場合
        } else {
            const mess = '保存した位置情報はありません。'
            this.props.dispatch({type: 'SET_ALERT' , shown: true, body: mess})
        }

    }

    fishingPlace() {

        if (this.state.showMap) {

            return (
                <MapComponent
                    id={'preview-map'}
                    options={G.defaultMapOptions}
                    defaultCenter={this.state.map.defaultCenter}
                    handleLoad={ref => this.handleMapLoad(ref)}
                    onClick={e => this.handleMapClick(e)}
                    markers={this.state.map.markers}
                    zoom={13}
                />
            )

        // 初期状態
        } else {

            return (
                <div className="before-located">
                    選択した画像から<br/>
                    位置情報を取得します。
                </div>
            )

        }
    }

    onDateCnange(date) {

        const e = {
            name: 'date',
            value: date,
        }
        this.handleInputChange(e)
    }

    onTideInfoChange(tide){

        const e = {
            name: 'tide',
            value: tide,
        }
        this.handleInputChange(e)
    }


    render() {


        const handler = e => this.handleInputChange(e)

        return (
            <div id={'contribution-create'}>
                <form onSubmit={e => this.onSubmit(e)} id={'contribution-form'}>

                    <div className={"form-row"}>
                        <label htmlFor="title">投稿タイトル</label>
                        <div className="input">
                            <FcText name="title" value={this.state.data.title} onChange={handler} />
                            {this.state.validation.errors.title && (

                                <div className="error">{this.state.validation.errors.title}</div>
                            ) }
                        </div>
                    </div>

                    <div className={"form-row"}>
                        <label htmlFor="">釣り場情報</label>

                        <div className="fishing-place">
                            {this.fishingPlace()}
                        </div>

                        <button
                            type="button"
                            onClick={ e => this.checkSavedLocation(e) }
                            className={'btn-secondary'}
                        >保存したスポットを取得</button>

                        {this.state.validation.errors.latlng && (

                            <div className="error">{this.state.validation.errors.latlng}</div>
                        ) }

                    </div>

                    <div className={"form-row"}>
                        <label htmlFor="file">画像動画を追加</label>
                        <div className="input">
                            <ImgThumbnails
                                imgs={this.state.resultFiles}
                                inputName={'resultFiles'}
                                onFileChange={ e => this.onFileChange(e) }
                            />
                        </div>
                    </div>

                    <div className={"form-row"}>
                        <label htmlFor="content">投稿内容</label>
                        <div className="input">
                            <FcTextarea name="content" value={this.state.data.content} onChange={handler} />
                            {this.state.validation.errors.content && (

                                <div className="error">{this.state.validation.errors.content}</div>
                            ) }
                        </div>
                    </div>

                    <div className={"form-row"}>
                        <label htmlFor="title">リンクURL（任意）</label>
                        <div className="input">
                            <FcText name="link" value={this.state.data.link} onChange={handler} />
                        </div>
                    </div>

                    <div className={"form-row"}>
                        <label htmlFor="file">画像動画を追加</label>
                        <div className="input">
                            <ImgThumbnails
                                imgs={this.state.tackleFiles}
                                inputName={'tackleFiles'}
                                onFileChange={ e => this.onFileChange(e) }
                            />
                        </div>
                    </div>

                    <div className={"form-row"}>
                        <label htmlFor="content">仕掛け・タックルの説明テキスト</label>
                        <div className="input">
                            <FcTextarea name="tackle_description" value={this.state.data.tackle_description} onChange={handler} />
                        </div>
                    </div>

                    <DateInput
                        value={this.state.data.date}
                        onChange={ e => this.onDateCnange(e)}
                    />

                    <TideInfo
                        data={this.state.data}
                        onChange={e => this.onTideInfoChange(e)}
                    />

                    <div className={"form-row"}>
                        <button className={'btn-primary'}>投稿する</button>
                    </div>


                </form>

            </div>

        );
    }
}
export default connect(state => state)( withRouter(ContributionCreate) )