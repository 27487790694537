



const Validate = (data) => {

    const errors = {}
    let hasError = false

    const checkEmail = (str) => {


        if ( !str ) {
            hasError = true
            return 'メールアドレスを入力してください。'
        }

        // メールアドレスの正規表現チェック
        const regex = /[\w\-._]+@[\w\-._]+\.[A-Za-z]+/
        if ( !str.match(regex) ) {
            hasError = true
            return 'メールアドレスの形式が不正です。'
        }

        return null
    }

    const checkPassword = (str) => {


        if ( !str ) {
            hasError = true
            return 'パスワードを入力してください。'
        }

        if ( str.length < 6 ) {
            hasError = true
            return '半角英数字で6文字以上入力してください。'
        }

        return null
    }

    const checkName = (str) => {


        if ( !str ) {
            hasError = true
            return 'ユーザー名を入力してください。'
        }

        return null
    }


    errors.email = checkEmail(data.email)
    errors.password = checkPassword(data.password)
    errors.user_name = checkName(data.user_name)


    return {
        hasError: hasError,
        errors: errors
    }
}
export default Validate

export const getValidation = () => {

    return {
        checked: false,
        hasError: false,
        isValid: false,
        errors: {
        }
    }
}

