import React, {useEffect, useRef, useState} from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as G from "../../GlobalResources"

import * as FileService from "../../services/FileService";
import * as ContributionService from "../../services/ContributionService";

import {Link, useHistory} from "react-router-dom";


const NormalList = (props) => {


    const history = useHistory()

    const onItemClick = (e, item) => {
        history.push('/view/' + item.data.id)

    }

    const Media = (props) => {

        if ( FileService.isVideo(props.file.meta) ) {

            return (
                <video
                    src={props.file.url}
                    muted
                    loop
                    preload="metadata"
                    controls
                    controlsList={'nodownload'}
                />
            )

        } else if ( FileService.isImage(props.file.meta) ) {

            return (
                <figure style={{backgroundImage: "url('" + props.file.url + "')"}}>
                    <img src={props.file.url} className="image-entity"/>
                </figure>
            )
        } else {
            return <></>
        }
    }

    return (
        <div id={'normal-list'}>

            <h2 className={'title'}>現在地付近の投稿</h2>

            <ul className={'current-item-list'}>

                {props.items.map( item => { return (

                    <li className={'list-item'} key={item.data.id}>
                        <div className={'inner'}>

                            <div className="media">
                                <Media file={item.file[0]} />
                            </div>

                            <div className="info" onClick={ e => onItemClick(e, item)}>
                                <div className="user">
                                    <div className="thumb"
                                         style={{backgroundImage: "url('" + item.data.user.profile_url + "')"}}>
                                    </div>
                                    <div className="text">
                                        <div className="user-name">{item.data.user.user_name}</div>
                                        <div className="date">{ ContributionService.getDateString(item.data, 'YYYY/MM/DD')}</div>
                                    </div>
                                </div>

                                <div className="title">
                                    {item.data.title}
                                </div>
                            </div>

                        </div>
                    </li>

                ) })}

            </ul>

        </div>

    );
}
export default NormalList
