import MapComponent from "../../components/atoms/MapComponent";
import SuitableMedia from "../../components/atoms/SuitableMedia";
import ContributionComments from "./ContributionComments";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import React from "react";
import * as G from "../../GlobalResources";
import ResultImages from "./ResultImages";

const DetailInfo = (props) => {

    const RenderPlace = () => {


        if (props.data && props.data.lat && props.data.lng){

            const lat = props.data.lat
            const lng = props.data.lng

            const options = Object.assign({}, G.defaultMapOptions)
            options.gestureHandling = 'cooperative'

            return (
                <div id="place-info">
                    <div className="inner">
                        <h2 className="title">釣り場情報</h2>
                        <MapComponent
                            id={'place-info-map'}
                            options={options}
                            defaultCenter={{lat: lat, lng: lng}}
                            markers={[{lat: lat, lng, lng}]}
                            zoom={11}
                        />
                        <div className="buttons">

                            <a href={'https://www.google.com/maps?q=' + lat + ',' + lng} className={'btn-secondary'} target="_blank">
                                地図アプリで案内を開始
                            </a>
                        </div>
                    </div>

                </div>
            )
        }
        return <></>
    }

    const TackleImg = () => {

        if (props.tackleImg.length) {

            return (
                <ResultImages items={props.tackleImg} />
            )

        } else {

            return (
                <div className="pictures">
                    <div className="no-image">
                        <div className="inner">
                            タックルの画像が登録されていません。
                        </div>
                    </div>
                </div>
            )
        }

    }

    const ForSignedInUser = () => {

        return (
            <div className="detail">
                <div id="tackle-info">
                    <div className={'inner'}>
                        <h2 className="title">仕掛け・タックルの情報</h2>
                        <TackleImg/>
                        <p className="description">
                            {props.data.tackle_description}
                        </p>
                    </div>
                </div>

                <RenderPlace/>

                <ContributionComments comments={props.comments}/>
            </div>
        )
    }
    const ForOthers = () => {

        return (
            <div className="detail">
                <div id="tackle-info">
                    <div className={'inner'}>
                        <h2 className="title">仕掛け・タックルの情報</h2>
                        <p className="description">タックルに関する情報はログイン後にご確認いただけます。</p>
                        <div className="buttons">
                            <Link to={'/sign-in'} className={'btn-primary'}>ログイン</Link>
                        </div>
                    </div>
                </div>

                <RenderPlace/>


                <div id="tackle-info">
                    <div className={'inner'}>
                        <h2 className="title">釣果に関するコメント</h2>
                        <p className="description">コメントはログイン後にご確認いただけます。</p>
                        <div className="buttons">
                            <Link to={'/sign-in'} className={'btn-primary'}>ログイン</Link>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // ログインをしていた場合は全ての情報を表示
    if ( props.authInfo && props.authInfo.isAuth ){
        return <ForSignedInUser/>
    // ログインしていない場合
    } else {
        return <ForOthers/>

    }

}
export default connect((state) => state)(DetailInfo)


