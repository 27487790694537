




export function getContentType(file) {

    if (file.contentType) return file.contentType
    if (file.type) return file.type
    if (file.meta && file.meta.contentType) return file.meta.contentType
    if (file.matadata && file.matadata.contentType) return file.matadata.contentType

}

export function isVideo(file) {

    const type = getContentType(file)
    // ファイルタイプが不明なら
    if (!type) return false
    return !type.indexOf('video')

}

export function isImage(file) {

    const type = getContentType(file)
    // ファイルタイプが不明なら
    if (!type) return false
    return !type.indexOf('image')
}

export function isImageOrVideo(file) {

    return isImage(file) || isVideo(file)
}

export function createUrl(file) {

    return window.URL.createObjectURL(file)
}

export async function createFileObjFromUrl(url) {
    const res   = await fetch('https://firebasestorage.googleapis.com/v0/b/kintarou-fishing.appspot.com/o/result_imgs%2FlLDcejW2Qr2jQXyPYBbp%2F1?alt=media&amp;token=e8936b45-4a5f-4e99-9ac7-a5c3fbd41ae1')
    const blob  = await res.blob()
    const file  = await new File([blob], "image.jpg")

    console.log(file.type)


}