import React, {useEffect, useRef, useState} from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as G from "../../GlobalResources"
import * as ContributionService from "../../services/ContributionService";
import * as StrageService from "../../services/StorageService";
import * as FileService from "../../services/FileService";
import {isVideo} from "../../services/FileService";
import {useHistory} from "react-router-dom";


const PickUpList = (props) => {

    const ref = useRef(null)

    const settings = {
        autoplay: true,
        autoplaySpeed: 3000,  // スライド表示時間
        speed: 1000,
        // cssEase: 'linear',
        dots: false,
        arrows: true,
        infinite: true,
        centerMode: true,
        centerPadding: "15%",
        slidesToShow: 1,
        lazyLoad:"progressive",

    };

    const history = useHistory()

    const onItemClick = (e, item) => {
        history.push('/view/' + item.data.id)

    }

    const Media = (props) => {

        if ( FileService.isVideo(props.file.meta) ) {

            return (
                <video
                    playsInline
                    src={props.file.url}
                    autoPlay
                    muted
                    loop
                />
            )

        } else if ( FileService.isImage(props.file.meta) ) {

            return (
                <figure style={{backgroundImage: "url('" + props.file.url + "')"}}>
                    <img src={props.file.url} className="image-entity"/>
                </figure>
            )
        } else {
            return <></>
        }
    }

    return (
        <div id={'pickup-list'}>
            <h2 className={'title'}>最近投稿された釣果</h2>
            <Slider {...settings} className={'slider'}>

                { props.items.map( item => { return (

                    <div className="slide-item" key={item.data.id} onClick={ e => onItemClick(e, item)}>
                        <div className={'inner'}>

                            <div className="media">
                                <Media file={item.file[0]} />
                            </div>

                            <div className="info">
                                <div className="user">
                                    <div className="thumb"
                                         style={{backgroundImage: "url('" + item.data.user.profile_url + "')"}}>
                                    </div>
                                    <div className="text">
                                        <div className="user-name">{item.data.user.user_name}</div>
                                        <div className="date">{ ContributionService.getDateString(item.data, 'YYYY/MM/DD')}</div>
                                    </div>
                                </div>

                                <div className="title">
                                    {item.data.title}
                                </div>
                            </div>

                        </div>
                    </div>

                ) })}

            </Slider>
        </div>
    );
}
export default PickUpList
