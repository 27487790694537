import React from 'react'

import {GoogleMap, LoadScript, MarkerClusterer, Marker } from "@react-google-maps/api";
import queryString from 'query-string';
import * as Geocode from '../../services/GeocodeService'

import * as ContributionService from "../../services/ContributionService.js";

import * as G from '../../GlobalResources'
import * as StrageService from '../../services/StorageService'
import {connect} from "react-redux";
import ContributeButton from "../../components/blocks/FrontSite/ContributeButton";
import * as FileService from "../../services/FileService";
import MapMarker from "../../components/atoms/MapMarker";

class MapView extends React.Component{


    // マップを操作するための参照
    mapRef = null
    searchInputRef = React.createRef();


    constructor (props) {
        super(props)

        this.state = {

            center: null,
            defaultCenter: {lat: 35.6809591, lng: 139.7673068},
            searchQuery: '',
            places: [],
            selected: null,
            saveedLocation: null,

            // 未ログインで投稿ボタンが押された場合のモーダル
            loginModalShown: false,
        }
    }


    async getContributions(center) {

        // マップの中心を更新
        this.setState({center: center, defaultCenter: center})

        // 投稿を配列で取得
        const res = await ContributionService.getAllAsArray()
        // stateに格納するために整形
        const places = res.map( p => {

            if ( !(p.lat && p.lng) ) {
                p.lat = p.location.geopoint.latitude
                p.lng = p.location.geopoint.longitude
            }
            return p
        })
        return places
    }

    async resolveSearchRequest(){

        // クエリパラメータを取得
        const q = queryString.parse( this.props.location && this.props.location.search )

        // 緯度経度が指定された場合
        if (q.latlng) {
            const latlng = q.latlng.split(',')

            return {
                lat: parseFloat( latlng[0] ),
                lng: parseFloat( latlng[1] ),
            }

        // 文字列で検索された場合
        } else if (q.adr) {
            this.setState({searchQuery: q.adr})
            return await Geocode.getLatlngFromAdress(q.adr)
        }
        // 指定がなかった場合は現在地を取得
        else {
            return await Geocode.getCurrentPos()
        }
    }

    checkSavedLocation(){

        const cookie = document.cookie
            .split('; ')
            .find(row => row.startsWith('tmpspot'))

        const place = cookie && cookie.split('=')[1];

        if (place) {
            const latlng = place.split(',')

            this.setState({
                savedLocation: {
                    lat: parseFloat( latlng[0] ),
                    lng: parseFloat( latlng[1] ),
                }
            })
        }

    }


    async componentDidMount() {

        // エリア検索のリクエストを解決
        const center = await this.resolveSearchRequest()
        // 該当エリアの投稿を表示
        const contributions = await this.getContributions(center)
        // stateに格納
        this.setState({places: contributions})
        // 保存されたスポットがあるか確認
        this.checkSavedLocation()
    }



    onCenterChanged() {
        if(this.mapRef){

            this.setState({ center:
                    {
                        lat: this.mapRef.getCenter().lat(),
                        lng: this.mapRef.getCenter().lng(),
                    }

            })
        }
    }



    onMapLoaded( ref ) {
        this.mapRef = ref
    }

    async onMarkerClick(e, marker) {

        const queue = [];

        // IDを元に投稿データを取得
        queue.push( ContributionService.get(marker.id) )
        // 投稿データのサムネイル画像URLを取得
        queue.push( StrageService.getContributionThumbnail(marker.id) )
        // 非同期に上記２つの処理を実行
        const res = await Promise.all(queue)

        const doc = res[0]
        doc.id = marker.id
        doc.thumb = res[1]

        this.setState({selected: doc})
    }

    renderMedia(file) {

        if ( FileService.isVideo(file.meta) ) {

            return (
                <video
                    playsInline
                    src={file.url}
                    autoPlay
                    muted
                    loop
                />
            )

        } else if ( FileService.isImage(file.meta) ) {

            return (
                <figure style={{backgroundImage: "url('" + file.url + "')"}}>
                    <img src={file.url} className="entity"/>
                </figure>
            )
        } else {
            return <></>
        }
    }

    renderContributionCard() {

        const doc = this.state.selected;

        if (doc) {
            return (
                <div className="contribution-card">
                    <div className="inner" onClick={e => this.onCardClick(e, doc)}>
                        <div className="media">
                            {this.renderMedia(doc.thumb)}
                        </div>
                        <div className="text">
                            <div className="comment">タイトル</div>
                            <div className="title">{doc.title}</div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="contribution-card">
                    <div className="inner">
                        <p className={'default-message'}>地図上のアイテムを選択してください。</p>
                    </div>
                </div>
            )
        }
    }

    onCardClick(e, doc) {

        this.props.history.push('/view/' + doc.id)

    }

    async goToCurrent(e){
        // 現在地の座標を取得
        const center = await Geocode.getCurrentPos();
        // マップの中心を現在地で更新
        this.setState({center: center, defaultCenter: center})
        // 該当エリアの投稿を表示
        const contributions = await this.getContributions(center)
        // stateに格納
        this.setState({places: contributions})
    }

    async saveSpot(e) {

        await this.goToCurrent(e)
        // 現在地の座標を文字列として生成
        const data = this.state.center.lat + ',' + this.state.center.lng + '; '
        const path = 'path=/; '
        const maxAge = 'max-age=172800; ' // 2days
        // Coolieにデータを保存
        document.cookie = "tmpspot=" + data + path + maxAge

        this.setState({
            savedLocation: {
                lat: this.state.center.lat,
                lng: this.state.center.lng,
            }
        })

    }

    onContributeClick(e){
        this.props.history.push('/contribute')

    }

    onSerchQueryChange(e){

        this.setState({searchQuery: e.target.value})

    }

    async onSearchFormSubmit(e){
        e.preventDefault()
        const center = await Geocode.getLatlngFromAdress(this.state.searchQuery)
        // 該当エリアの投稿を表示
        const contributions = await this.getContributions(center)
        // stateに格納
        this.setState({places: contributions})
        // inputのフォーカスを外す
        this.searchInputRef.current.blur()
    }

    render() {


        const clusterStyles = [
            {
                textColor: "white",
                textSize: 18,
                url: process.env.PUBLIC_URL + "/assets/img/circle.png",
                height: 60,
                width: 60,
            },
            {
                textColor: "white",
                textSize: 18,
                url: process.env.PUBLIC_URL + "/assets/img/circle.png",
                height: 60,
                width: 60,
            },
            {
                textColor: "white",
                textSize: 18,
                url: process.env.PUBLIC_URL + "/assets/img/circle.png",
                height: 60,
                width: 60,
            },
        ]


        const ClusterOptions = {
            gridSize: 50,
            styles: clusterStyles,
            maxZoom: null,
        }

        return (
            <div id={'map-view'}>

                <LoadScript googleMapsApiKey="AIzaSyARIrdxgRJUJw88Q9lHx5mKVocgQGfYc6w">
                    <div id={'map'}>


                        <GoogleMap
                            onLoad={ref => this.onMapLoaded(ref)}
                            options={G.defaultMapOptions}
                            mapContainerStyle={{width: '100%', height: '100%'}}
                            center={this.state.defaultCenter}
                            zoom={11}
                            onClick={e => e}
                            onCenterChanged={
                                () => this.onCenterChanged()

                            }
                        >

                            {this.state.savedLocation && window.google && (

                                <Marker
                                    key={'aaaaa'}
                                    position={this.state.savedLocation}
                                    icon={{
                                        url: "/assets/img/icons/marker-bookmark.png",
                                        scaledSize: new window.google.maps.Size(50, 50),
                                    }}
                                />
                            )}


                            <MarkerClusterer
                                options={ClusterOptions}
                            >

                                {(clusterer) =>
                                    this.state.places.map(
                                        (marker) =>
                                            (
                                                <Marker
                                                    key={marker.id}
                                                    clusterer={clusterer}
                                                    position={{
                                                        lat: marker.lat,
                                                        lng: marker.lng,
                                                    }}
                                                    icon={{
                                                        url: "/assets/img/icons/marker-orange.png",
                                                        scaledSize: new window.google.maps.Size(50, 50),
                                                    }}
                                                    onClick={ e => this.onMarkerClick(e, marker) }
                                                />
                                            )
                                    )
                                }
                            </MarkerClusterer>
                        </GoogleMap>

                        <div className="top-control">
                            <form
                                className="search"
                                onSubmit={e => this.onSearchFormSubmit(e) }
                            >
                                <input
                                    ref={this.searchInputRef}
                                    className={'input'}
                                    type="text"
                                    value={this.state.searchQuery}
                                    onChange={ e => this.onSerchQueryChange(e) }
                                    placeholder={'地名・スポットなどで検索'}
                                />
                            </form>
                        </div>

                        <div className="bottom-control">

                            <div className="buttons">
                                <button onClick={e => this.saveSpot(e)} className={'save-spot'} type="button" >
                                    <span>スポットを<br/>一時保存</span>
                                </button>
                                <button onClick={e => this.goToCurrent(e)} className={'current'} type="button" ><span>現在地</span></button>
                                <ContributeButton redirectTo={'/map'}/>
                                <button
                                     className={'area-search'}
                                     onClick={ e => this.getContributions(this.state.center)  }
                                     type="button"
                                >
                                    <span>このエリアで再検索</span>
                                </button>
                            </div>


                            { this.renderContributionCard() }

                        </div>

                    </div>
                </LoadScript>
            </div>
        );
    }
}
export default connect(state => state)(MapView)

































