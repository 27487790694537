import React, {useEffect, useState} from 'react'
import * as G from "../../GlobalResources"

import MainVisual from "./MainVisual";
import PickUpList from "./PickUpList";
import NormalList from "./NormalList";
import * as ContributionService from "../../services/ContributionService";
import * as StrageService from "../../services/StorageService";
import {connect} from "react-redux";
import {db} from "../../services/Firebase";



const Index = (props) => {


    const getContribution = async (cid) => {

        const queue = []
        // IDを元に投稿データを取得
        queue.push( ContributionService.get(cid) )
        // 投稿データの画像URLを取得
        queue.push( StrageService.downloadResultImgs(cid) )
        // 非同期に上記２つの処理を実行
        const res = await Promise.all(queue)

        return {
            data: res[0],
            file: res[1],
        }

    }

    const [items, setItems] = useState([])

    useEffect(async () => {

        const contributions = db.collection('contributions')
        props.dispatch({type: 'SET_LOADING', loading: true})

        const queue = []

        const snapshot = await contributions
            .orderBy('created_at', 'desc')
            .limit(5)
            .get();

        snapshot.docs.forEach(doc => {
            queue.push( getContribution(doc.id) )

        })

        const res = await Promise.all(queue)

        props.dispatch({type: 'SET_LOADING', loading: false})

        setItems(res)

    }, [])


    return (
        <div id={'index'}>

            <MainVisual />

            <PickUpList items={items} />
            <NormalList items={items} />


            <section id="sect-1">
                <div className="inner">
                    <h2>つり吉 金太郎とは？</h2>

                    <div className="row">
                        <div className="image">
                            <img src={G.IMG_URL('icons/map-marker.png')} alt=""/>
                        </div>
                        <h3 className={'title'}>マップ上に直接情報を表示</h3>
                        <p className={'description'}>
                            マップ画面上に釣りスポットや釣果の投稿情報を直接表示します。
                            直感的な検索がしやすいだけでなく、目的のスポットまでのルート確認にもなります。
                        </p>
                    </div>
                </div>
            </section>

            {/*<section id={'tmp-message'}>*/}
            {/*    <div className="inner">*/}
            {/*        本サービスは、*/}
            {/*    </div>*/}
            {/*</section>*/}

            <footer id={'footer'}>
                <div className="inner">
                    Since 2021 June All Right Reserved.
                </div>
            </footer>
        </div>

    )

}
export default connect(state => state)(Index)
