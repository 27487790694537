import React, {useEffect, useRef, useState} from 'react'
import SuitableMedia from "../../components/atoms/SuitableMedia";



const ImgThumbnails = (props) => {

    const [imgs, setImgs] = useState(props.imgs)

    const fileInput = useRef(null);

    useEffect( () => {

        setImgs(props.imgs)
    })

    const onUploadButtonClick = (e) => {

        fileInput.current.click()
    }

    const onFileChange = (e) => {


        if (props.onFileChange){
            props.onFileChange(e)

        }
    }


    return (
        <div className={'thumbnails'}>
            <ul className={'img-list'}>
                {imgs.map( file => { return (

                    <li className={'item'} key={file.url}>
                        <SuitableMedia file={file.obj} src={file.url} />
                    </li>
                )
                })}
                <li className={'add-item'}>
                    <button onClick={ e => onUploadButtonClick(e) } type='button'>
                        <span className="label">add image</span>
                    </button>
                </li>
            </ul>
            <div className="buttons">
                <button
                    className={'add-button'}
                    type="button"
                    onClick={ e => onUploadButtonClick(e) }
                    className={'btn-secondary'}
                >ファイルを選択</button>
                <input
                    name={props.inputName}
                    style={{display: 'none'}}
                    type="file"
                    ref={fileInput}
                    onChange={ e => onFileChange(e) }
                />
            </div>
        </div>
    )
}

export default ImgThumbnails