import React, {useEffect, useRef, useState} from 'react'
import {connect} from "react-redux";
import * as ContributionService from "../../services/ContributionService";

const TideInfo = (props) => {

    const [data, setData] = useState(props.data)

    return (
        <div className={'tide-info'}>

            <h2 className="title">日付・潮汐情報</h2>


            <table>
                <tbody>
                <tr>
                    <th>日付</th>
                    <td>{ ContributionService.getDateString(data)}</td>
                </tr>
                <tr>
                    <th>該当エリア</th>
                    <td>{data.tide.pointName}</td>
                </tr>
                <tr>
                    <th>日平均潮位</th>
                    <td>{data.tide.averageTide}cm</td>
                </tr>
                <tr>
                    <th>日合計潮位</th>
                    <td>{data.tide.totalTide}cm</td>
                </tr>
                <tr>
                    <th>潮名</th>
                    <td>{data.tide.siona}</td>
                </tr>
                <tr>
                    <th>満潮時刻</th>
                    <td>
                        {data.tide.highTideTime[0] && datetostr(data.tide.highTideTime[0], 'hh:mm')} {data.tide.highTide[0]}cm <br/>
                        {data.tide.highTideTime[1] && datetostr(data.tide.highTideTime[1], 'hh:mm')} {data.tide.highTide[1]}cm <br/>
                    </td>
                </tr>
                <tr>
                    <th>干潮時刻</th>
                    <td>
                        {data.tide.lowTideTime[0] && datetostr(data.tide.lowTideTime[0], 'hh:mm')} {data.tide.lowTide[0]}cm <br/>
                        {data.tide.lowTideTime[1] && datetostr(data.tide.lowTideTime[1], 'hh:mm')} {data.tide.lowTide[1]}cm <br/>
                    </td>
                </tr>

                </tbody>
            </table>

        </div>
    )
}

export default TideInfo

const datetostr = (dateStr, format, is12hours) => {

    const date = new Date(dateStr)


    var weekday = ["日", "月", "火", "水", "木", "金", "土"];
    if (!format) {
        format = 'YYYY/MM/DD(WW) hh:mm:dd'
    }
    var year = date.getFullYear();
    var month = (date.getMonth() + 1);
    var day = date.getDate();
    var weekday = weekday[date.getDay()];
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var secounds = date.getSeconds();

    var ampm = hours < 12 ? 'AM' : 'PM';
    if (is12hours) {
        hours = hours % 12;
        hours = (hours != 0) ? hours : 12; // 0時は12時と表示する
    }

    var replaceStrArray =
        {
            'YYYY': year,
            'Y': year,
            'MM': ('0' + (month)).slice(-2),
            'M': month,
            'DD': ('0' + (day)).slice(-2),
            'D': day,
            'WW': weekday,
            'hh': ('0' + hours).slice(-2),
            'h': hours,
            'mm': ('0' + minutes).slice(-2),
            'm': minutes,
            'ss': ('0' + secounds).slice(-2),
            's': secounds,
            'AP': ampm,
        };

    var replaceStr = '(' + Object.keys(replaceStrArray).join('|') + ')';
    var regex = new RegExp(replaceStr, 'g');

    const ret = format.replace(regex, function (str) {
        return replaceStrArray[str];
    });

    return ret;
}