



const Validate = (data) => {

    const errors = {}
    let hasError = false

    const checkTitle = (str) => {

        // 文字列に何らかの値があればエラーなし
        if ( str ) return null
        // 値がfalse判定ならエラー
        hasError = true
        return 'タイトルを入力してください。'
    }

    const checkLatlng = (lat, lng) => {

        // 緯度経度が設定されていればエラーなし
        if ( lat && lng ) return null
        // 値がfalse判定ならエラー
        hasError = true
        return 'マップをクリックして位置を選択してください。'
    }

    const checkContent = (content) => {

        // 文字列に何らかの値があればエラーなし
        if ( content ) return null
        // 値がfalse判定ならエラー
        hasError = true
        return '投稿内容を記載してください。'
    }


    errors.title = checkTitle(data.title)
    errors.latlng = checkLatlng(data.lat, data.lng)
    errors.content = checkContent(data.content)


    return {
        hasError: hasError,
        errors: errors
    }
}
export default Validate

export const getValidation = () => {

    return {
        checked: false,
        hasError: false,
        isValid: false,
        errors: {
            title: null,
        }
    }
}

