import React, {useEffect, useRef, useState} from 'react'
import * as UserService from "../../services/UserService.js";
import * as G from "../../GlobalResources";
import {connect} from "react-redux";
import * as File from "../../services/FileService";

const EditPassword = (props) => {


    const onChangeActive = (e) => {

        if( props.onChangeActive ) props.onChangeActive(e)
    }

    const onOldPasswordChenge = (e) => {

        console.log(props.oldPassword)

        if (props.onInputChange) props.onInputChange(e)

    }

    const onNewPasswordChenge = (e) => {


        if (props.onInputChange) props.onInputChange(e)

    }

    return (

        <>

            <div className={"form-row"}>
                <label htmlFor="old_password">現在のパスワード</label>
                <div className="input">
                    <input
                        type={'password'}
                        name="old_password"
                        value={props.oldPassword}
                        onChange={e => onOldPasswordChenge(e)}
                    />
                </div>
            </div>

            <div className={"form-row"}>
                <div className="checkbox">
                    <input
                        type={'checkbox'}
                        id={'active_check'}
                        name="active"
                        checked={props.editPassword}
                        onChange={ e => onChangeActive(e) }
                    />
                    <label htmlFor={'active_check'}>パスワードを変更する</label>
                </div>
            </div>

            { props.editPassword && (

                <div className={"form-row"}>
                    <label htmlFor="new_password">新しいパスワード</label>
                    <div className="input">
                        <input
                            type={'password'}
                            name="new_password"
                            value={props.newPassword}
                            onChange={e => onNewPasswordChenge(e)}
                        />
                    </div>
                </div>

            )}

        </>
    )

}
export default EditPassword