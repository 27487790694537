import Geocode from "react-geocode";

const apiKey = 'AIzaSyARIrdxgRJUJw88Q9lHx5mKVocgQGfYc6w'


Geocode.setApiKey(apiKey)
Geocode.setLanguage("ja");
Geocode.setRegion("jp");

export const getLatlngFromAdress = async (str) => {

    // console.log(str)

    const res = await Geocode.fromAddress(str)
    const { lat, lng } = res.results[0].geometry.location;

    return {
        lat: lat,
        lng: lng,
    }
}


const defaultPos = { lat: 35.6809591, lng: 139.7673068 }

export const getCurrentPos = async() => {

    try {

        const pos = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        return {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
        }

    } catch (e) {

        // alert('現在地情報の取得に失敗しました。端末の許可設定を確認してください。')
        return defaultPos
    }

}


