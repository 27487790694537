

export const PUBLIC_URL = (path = '') => {
    return process.env.PUBLIC_URL + '/' + path
}


export const ASSET_URL = (path = '') => {
    return process.env.PUBLIC_URL + '/assets/' + path
}


export const IMG_URL = (path = '') => {
    return process.env.PUBLIC_URL + '/assets/img/' + path
}


const mapStyleOptions = [

    {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
            { visibility: 'off' }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
            { visibility: 'off' }
        ]
    },
]

export const defaultMapOptions = {
    disableDefaultUI: true,
    styles: mapStyleOptions,
    zoomControl: false,
    scrollwheel: true,
    gestureHandling: 'greedy',
}

export function dateFormat(date, format) {

    format = format.replace(/YYYY/, date.getFullYear());
    format = format.replace(/MM/, date.getMonth() + 1);
    format = format.replace(/DD/, date.getDate());

    return format;
}