import React, {useEffect, useRef, useState} from 'react';
import * as CommentService from '../../services/CommentService'
import {connect} from "react-redux";
import TextareaAutosize from 'react-textarea-autosize'

class CommentModal extends React.Component {

    constructor (props) {
        super(props)

        this.state = {
            isShown: props.isShown,
            contribution: props.contribution,
            content: '',
        }
        this.textInput = React.createRef();
    }

    onChange = (e) => {

        this.setState({content: e.target.value})
    }
    componentDidUpdate(prevProps){


        if (this.props.isShown !== prevProps.isShown) {
            this.setState({ isShown: this.props.isShown})
        }
        if (this.props.contribution !== prevProps.contribution) {
            this.setState({ contribution: this.props.contribution})
        }

        if (this.textInput.current) {
            this.textInput.current.focus()
        }
    }
    modalClose(e){

        if (this.props.onModalClose){
            this.props.onModalClose()
        }
    }

    async onSubmit(e){

        e.preventDefault()

        if (!this.state.content) {
            alert('コメントが空欄では送信できません。')
            return
        }

        const data = {
            content: this.state.content,
            uid: this.props.authInfo.user.uid,
            cid: this.state.contribution.id,
        }
        await CommentService.create(data)

        this.setState({content: ''})

        if ( this.props.onModalClose ){
            this.props.onModalClose()
        }
        if ( this.props.onCommentSubmit ) {
            this.props.onCommentSubmit()
        }
    }

    render() {

        if (this.state.isShown) {
            return (
                <aside id={'comment-modal'} onClick={e => this.modalClose(e)}>

                    <div className="inner" onClick={e => e.stopPropagation()}>

                        <form onSubmit={e => this.onSubmit(e)} className={'comment-form'}>
                            <button
                                onClick={e => this.modalClose(e)}
                                className={'close'}
                                type="button"
                            >
                                <span className={'label'}>close</span>
                            </button>

                            <TextareaAutosize
                                ref={this.textInput}
                                className={'input'}
                                value={this.state.content}
                                placeholder={'コメントを入力…'}
                                onChange={e => this.onChange(e)}
                            />

                            <button className={'submit'}>
                                <span className="label">送信</span>
                            </button>
                        </form>
                    </div>
                </aside>
            )
        } else {
            return <></>
        }
    }
}
export default connect(state => state)(CommentModal)

