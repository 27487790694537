import React, {useEffect, useState} from 'react';
import { Redirect } from 'react-router-dom';
import {connect} from "react-redux";

function Auth(props) {

    const [authState, setAuthState] = useState(props.auth)

    useEffect(()=>{
        setAuthState(props.auth)
    })

    if (!authState.checked) {

        return <></>
    }
    if (authState.isAuth) {
        return props.children

    } else {
        return <Redirect to="/sign-in" />
    }

}
export default connect()(Auth)