import { createStore } from 'redux'
import * as G from "./GlobalResources";

const initData = {

    authInfo: {
        checked: false,
        isAuth: null,
        user: null,
    },

    mainRef: null,
    wrapperRef: null,
    scrolllocked: false,
    loading: false,

    alert: {
        shown: false,
        body: '',
    },

    uploadTask: {
        processing: false,
        queue: [],
    }

}

export function mainReducer(state = initData, action){

    const st = Object.assign({}, state)

    switch (action.type) {
        case 'UPDATE_AUTH_INFO':
            return updateAuthInfo(st, action)

        case 'SET_MAIN_REF':
            return setmainRef(st, action)

        case 'SET_WRAPPER_REF':
            return setWrapperRef(st, action)

        case 'SET_SCROLLLOCKED':
            return setScrolllocked(st, action)

        case 'SET_LOADING':
            return setLoading(st, action)

        case 'SET_ALERT':
            return setAlert(st, action)

        case 'SET_UPLOAD_TASK':
            return setUploadTask(st, action)

        default:
            return st

    }
}

const setUploadTask = (state, action) => {

    state.uploadTask = {
        processing: action.processing,
        queue: action.queue,
    }
    return state
}

const updateAuthInfo = (state, action) => {

    state.authInfo = action.authInfo
    return state
}


const setmainRef = (state, action) => {

    state.mainRef = action.mainRef
    return state

}

const setWrapperRef = (state, action) => {

    state.wrapperRef = action.wrapperRef
    return state

}

const setScrolllocked = (state, action) => {

    state.scrolllocked = action.locked
    return state

}

const setLoading = (state, action) => {

    state.loading = action.loading
    // スクロールロックの値も同時に変更
    return setScrolllocked(state, {locked: action.loading})

}

const alertClose = (state) => {

    state.alert = {
        shown: false,
        body: '',
    }
    return state

}

const setAlert = (state, action) => {

    if (!action.shown) return alertClose(state)

    state.alert = {
        shown: action.shown,
        body: action.body,
    }
    return state
}




export default createStore(mainReducer)