import React, {useEffect, useRef, useState} from 'react'
import * as UserService from "../../services/UserService.js";
import * as G from "../../GlobalResources";
import {connect} from "react-redux";
import * as File from "../../services/FileService";

const SetImage = (props) => {


    const [coverImg, setCoverImg] = useState(props.coverUrl)
    const [profileImg, setProfileImg] = useState(props.profileUrl)

    const coverInputRef = useRef(null)
    const profileInputRef = useRef(null)

    useEffect(() => {

        setCoverImg(props.coverUrl)
        setProfileImg(props.profileUrl)

    }, [props.coverUrl, props.profileUrl])

    const onClick = (e, ref) => {

        ref.current.click();
    }

    const onCoverChange = (e, ref) => {

        const inputName = e.target.name
        const file = e.target.files[0]
        const url = File.createUrl(file)

        setCoverImg(url)
        if(props.onCoverChange) props.onCoverChange(file)

    }

    const onProfileChange = (e, ref) => {

        const inputName = e.target.name
        const file = e.target.files[0]
        const url = File.createUrl(file)

        setProfileImg(url)

        if(props.onProfileChange) props.onProfileChange(file)
    }


    return (

        <>

            <div className="set-images">
                <div
                    className="cover"
                    onClick={e => onClick(e, coverInputRef)}
                    style={{backgroundImage: 'url(' + coverImg + ')',}}
                >
                    <button className={'edit-button'}>
                        <span className={'label'}>edit</span>
                    </button>
                </div>
                <div
                    className="profile"
                    onClick={e => onClick(e, profileInputRef)}
                    style={{backgroundImage: 'url(' + profileImg + ')',}}
                >
                    <button className={'edit-button'}>
                        <span className={'label'}>edit</span>
                    </button>
                </div>
            </div>

            <input
                form={'user-edit-form'}
                type="file"
                ref={coverInputRef}
                name="cover"
                style={{display: 'none'}}
                onChange={e => onCoverChange(e)}
            />
            <input
                form={'user-edit-form'}
                type="file"
                ref={profileInputRef}
                name="cover"
                style={{display: 'none'}}
                onChange={e => onProfileChange(e)}
            />

        </>
    )

}
export default SetImage