import {db, createGeoPoint} from "./Firebase.js";
import * as UserService from './UserService'


const comments = db.collection('comments')


function replaceLf(text) {

    if ( typeof (text) == "string" || text instanceof String ){
        return text.replaceAll('\\n', '\n')
    }
    return text
}


export async function get(id, replace = true) {

    const cont = await comments.doc(id).get();
    const data = cont.data()


    const user = await UserService.get(data.uid)
    data.user = user.data()

    if (replace){
        data.content = replaceLf(data.content)
    }

    return data
}


export async function getAll(cid) {

    const snapshot = await comments.where('cid', '==', cid).get();
    return snapshot.docs
}

export async function getAllAsArray(cid = null, uid = null) {

    let query = comments
    // 特定の投稿に対するコメントを取得する場合
    if (cid) {
        query = query.where('cid', '==', cid)
    }

    // 特定のユーザーのみのコメントを取得する場合
    if (uid) {
        query = query.where('uid', '==', uid)
    }

    const snapshot = await query.orderBy('created_at').get();

    const queue = []

    const docs = snapshot.docs.map( doc => {
        // データをオブジェクトとして取得
        const data = doc.data()
        // IDを格納
        data.id = doc.id
        // コメントの改行を反映
        data.content = replaceLf(data.content)
        // userデータを取得するプロミスを用意
        queue.push( UserService.getAsArray(data.uid) )

        return data
    });

    // 非同期で並列してユーザーの情報を取得
    const res = await Promise.all(queue)

    // 全てのユーザー情報が取得できたら上で用意したコメントの配列に格納
    for ( const [key, value] of res.entries() ) {

        docs[key].user = value
    }

    return docs
}

export async function create(data) {

    // 改行を保持して扱うため手動でエスケープ
    data.content = data.content.replaceAll('\n', '\\n')
    // 作成日と更新日をセット
    data.created_at = new Date()
    data.updated_at = data.created_at

    // docリファレンスを取得
    const doc = comments.doc()
    // 新しいデータを保存
    doc.set(data)
    // インサートしたダータのIDをリターン
    return doc.id
}

