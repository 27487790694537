import React from 'react'

export default class FcTextarea extends React.Component{

    constructor (props) {
        super(props)

        this.state = { value: props.value }
    }

    handleChhange(e) {
        this.setState({value: e.target.value})

        if (this.props.onChange) {

            this.props.onChange({

                name: e.target.getAttribute('name'),
                value: e.target.value,
                target: e.target,
            })
        }
    }

    render() {

        return (
            <textarea
                name={this.props.name}
                value={this.state.value}
                onChange={ e => this.handleChhange(e) } />

        )
    }
}