import Dialog from "../Dialog";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";

const ContributeButton = (props) => {

    const [modalShown, setModalShown] = useState(false)
    const [progress, setProgress] = useState(false)
    const history = useHistory()

    // アップロード状況をモニタリングする変数
    const processing = props.uploadTask.processing


    useEffect(()=>{

        let intervalId = null

        // アップロード状況中であればモニタリングを開始
        if (processing) {

            intervalId = setInterval( (e) => {

                const queue = props.uploadTask.queue

                let total = 0
                let done = 0

                queue.forEach( item => {

                    total +=  item.snapshot.totalBytes
                    done +=  item.snapshot.bytesTransferred

                })

                const rate = (done / total) * 100
                const rounded = Math.round(rate * 10) / 10

                setProgress(rounded)

                // 進捗が100％を超えた場合の処理
                if (rounded >= 100) {

                    // インターバルをクリア
                    clearInterval(intervalId)
                    // アップロードタスクをクリア
                    props.dispatch({
                        type: 'SET_UPLOAD_TASK' ,
                        processing: false,
                        queue: [],
                    })
                    // 投稿が完了した旨をアラートで通知
                    const message = '投稿ファイルのアップロードが完了しました。'
                    props.dispatch({type: 'SET_ALERT' , shown: true, body: message})
                }

            }, 2000)
        }
        // インターバルが設定されていた場合はクリア
        return () => {
            if (intervalId) clearInterval(intervalId)
        };

    }, [])


    const onContributeButtonClick = () => {
        // ログインしていれば投稿ページに遷移
        if (props.authInfo.isAuth) {
            history.push('/contribute')

            // 未ログインの場合はログインモーダルを表示
        } else {
            setModalShown(true)
        }
    }

    // 進捗中ボタンがタップされた場合
    const onProgressButtonClick = () => {

        // 投稿が完了した旨をアラートで通知
        const message = '現在、投稿ファイルのアップロードが進行中です。'
        props.dispatch({type: 'SET_ALERT' , shown: true, body: message})
    }

    const renderContent = () => {

        let content;
        // アップロードが進行中だった場合は進捗情報を出力
        if (processing) {
            return (
                <button
                    className={'progress'}
                    onClick={ e => onProgressButtonClick() }
                    type="button" >
                    <small>送信中</small><br/>
                    <span>{progress}%</span>
                </button>
            )

            // 通常時は投稿ボタンを表示
        } else {
            return (
                <button
                    className={'contribute'}
                    onClick={ e => onContributeButtonClick(e) }
                    type="button" >
                    <span>新規投稿</span>
                </button>
            )
        }
    }


    return (
        <>
            {renderContent()}

            <Dialog
                shown={modalShown}
                body={'釣果の投稿を行うにはログインが必要です。'}
                close={'閉じる'}
                apply={'ログイン'}
                onClose={ e => setModalShown(false) }
                onAplly={ e => history.push('/sign-in?redirectTo=' + props.redirectTo || '/') }
            />
        </>
    )
}
export default connect (state => state) (ContributeButton)


const Progress = (processing) => {

    if ( processing ) {
        return <span>process</span>
    }
    return <span>新規投稿</span>

}
















