import React from 'react'
import queryString from 'query-string';

import FcText from '../../components/atoms/FcText';

import * as UserService from "../../services/UserService.js";
import FcPassword from "../../components/atoms/FcPassword";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

class UserSignUp extends React.Component{

    constructor (props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            redirectTo: null,
        }
    }

    handleChange(e) {

        this.setState({ [e.name]: e.value })
    }

    async onSubmit(e) {
        e.preventDefault()

        try {
            const res = await UserService.signIn(this.state.email, this.state.password)
            this.props.history.push(this.state.redirectTo || '/')
        } catch (e) {
            const mess = 'メールアドレスもしくはパスワードが不正です。'
            this.props.dispatch({type: 'SET_ALERT' , shown: true, body: mess})
        }
    }

    async demoSignIn(e) {
        e.preventDefault()

        const res = await UserService.signIn('mail@xeforward.tokoyo', 'aaaabbbb')
        this.props.history.push(this.state.redirectTo || '/')

    }

    componentDidMount(){

        const q = queryString.parse( this.props.location && this.props.location.search )
        // ログイン後のリダイレクト先が指定されていればステートにセット
        this.setState({redirectTo: q.redirectTo})



    }

    render() {
        const handler = e => this.handleChange(e)

        return (
            <div id={'user-sign-in'}>
                <h1>ログイン</h1>

                <form onSubmit={ e => this.onSubmit(e) } className={'signin-form'}>
                    <div className="row">
                        <FcText name="email" value={this.state.email} onChange={handler} />
                    </div>
                    <div className="row">
                        <FcPassword name="password" value={this.state.password} onChange={handler} />
                    </div>
                    <div className="buttons">
                        <button className={'btn-primary'}>ログイン</button>
                    </div>
                    <div className="sign-up">
                        <Link to="/sign-up">アカウント新規作成</Link>
                    </div>
                    <br/>
                    <div className="sign-up">
                        <Link to={'/'} onClick={e => this.demoSignIn(e)}>DEMO</Link>
                    </div>

                </form>

            </div>
        )
    }
}
export default connect(state => state)(UserSignUp)