import Dialog from "./../../components/blocks/Dialog";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";

const CommentButton = (props) => {

    const [modalShown, setModalShown] = useState(false)
    const history = useHistory()

    const onClick = (e) => {
        // ログインしていれば親コンポーネントのonClickを実行
        if (props.authInfo.isAuth) {

            if (props.onClick) props.onClick(e)

        // 未ログインの場合はログインモーダルを表示
        } else {
            setModalShown(true)
        }
    }

    // ログイン後のリダイレクト先を設定。Defaultはトップ
    const redirectTo = props.redirectTo || '/'

    return (
        <>
            <button
                className={'comment'}
                onClick={ e => onClick(e) }
                type="button" >
                <span>コメント</span>
            </button>

            <Dialog
                shown={modalShown}
                body={'コメントを行うにはログインが必要です。'}
                close={'閉じる'}
                apply={'ログイン'}
                onClose={ e => setModalShown(false) }
                onAplly={ e => history.push('/sign-in?redirectTo=' + redirectTo) }
            />
        </>
    )
}
export default connect (state => state) (CommentButton)

