import {db, createGeoPoint} from "./Firebase.js";
import * as geofire from "geofire-common";
import * as UserService from './UserService'
import * as G from '../GlobalResources'



const contributions = db.collection('contributions')




function replaceLf(text) {

    if ( typeof (text) == "string" || text instanceof String ){
        return text.replaceAll('\\n', '\n')
    }
    return text
}


export async function get(id, replace = true) {

    const cont = await contributions.doc(id).get();
    const data = cont.data()

    // データ自身はIDを保持していないので手動で格納
    data.id = id

    const user = await UserService.get(data.uid)
    data.user = user.data()

    if (replace){
        data.content = replaceLf(data.content)
        data.tackle_description = replaceLf(data.tackle_description)
    }

    return data
}


export async function getAll() {

    const snapshot = await contributions.get();
    return snapshot.docs
}

export async function getAllAsArray() {

    const snapshot = await contributions.orderBy('geohash').get();

    const docs = snapshot.docs.map( doc => {
        let data = doc.data()
        data.id = doc.id
        return data
    });
    return docs
}

export async function create(data, user) {

    const lat = data.lat
    const lng = data.lng

    // 位置情報が不正な場合はエラー
    if ( !(lat || lng) ){
        throw new Error('invalid location info.')
    }

    // IDをセット
    data.uid = user.uid

    // ロケーションに関する情報を設定
    data.geohash = geofire.geohashForLocation([lat, lng])
    data.geopoint = createGeoPoint(lat, lng)

    // 作成日と更新日をセット
    data.created_at = new Date()
    data.updated_at = data.created_at

    // 改行を保持して扱うため手動でエスケープ
    data.content = data.content.replaceAll('\n', '\\n')
    data.tackle_description = data.tackle_description.replaceAll('\n', '\\n')

    // docリファレンスを取得
    const doc = contributions.doc()
    // 新しいデータを保存
    doc.set(data)
    // インサートしたDataのIDをリターン
    return doc.id
}

export async function update(id, data) {

    const lat = data.lat
    const lng = data.lng

    // 位置情報が不正な場合はエラー
    if ( !(lat || lng) ){
        throw new Error('invalid location info.')
    }

    // ロケーションに関する情報を設定
    data.geohash = geofire.geohashForLocation([lat, lng])
    data.geopoint = createGeoPoint(lat, lng)

    // 更新日をセット
    data.updated_at = new Date()

    // 改行を保持して扱うため手動でエスケープ
    data.content = data.content.replaceAll('\n', '\\n')
    data.tackle_description = data.tackle_description.replaceAll('\n', '\\n')

    // docリファレンスを取得
    const doc = contributions.doc(id)
    // 新しいデータを保存

    console.log(data)

    // doc.update(data)
    // インサートしたダータのIDをリターン
    return doc.id
}

export function getDateString(data) {

    if ( data.date ){
        const date = data.date.toDate()
        return G.dateFormat(date, 'YYYY/MM/DD')
    }
    return false

}

