import React, {useEffect, useState} from 'react'
import {Link, useHistory} from "react-router-dom";
import * as G from "../../GlobalResources";



const ContributionComments = (props) => {

    const history = useHistory()
    const [comments, setComments] = useState(props.comments)

    useEffect(()=>{

        setComments(props.comments)
    })

    return (
        <div id="comments">
            <div className="inner">
                <h2 className="title">釣果に関するコメント</h2>
                <ul className={'comment-list'}>
                    {comments.map(comment => {

                        const icon = comment.user.profile_url || G.IMG_URL('icons/user.png')
                        return (
                        <li key={comment.id} className={'row'}>
                            <div className="head">
                                <div
                                    className="thumb"
                                    style={{backgroundImage: 'url(' + icon + ')',}}
                                />
                                <div className="text">
                                    <div className="user-name">{comment.user.user_name}</div>
                                    <div className="date">2021/01/31</div>
                                </div>
                            </div>
                            <div className="body">
                                <div className="content">{comment.content}</div>
                            </div>
                        </li>
                    ) })}
                </ul>
            </div>
        </div>
    )
}

export default ContributionComments