import firebase from "firebase"


var firebaseConfig = {
    apiKey: "AIzaSyCl8n9z-k_gbR0m061nnEYgLGJfw25XhV8",
    authDomain: "kintarou-fishing.firebaseapp.com",
    projectId: "kintarou-fishing",
    storageBucket: "kintarou-fishing.appspot.com",
    messagingSenderId: "1045462276578",
    appId: "1:1045462276578:web:808ecc9d376b6c2c7f41bb",
    measurementId: "G-XJL08SYM8W"
}


firebase.initializeApp(firebaseConfig)
export default firebase
export const db = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()

export const createGeoPoint = (lat, lng) => {

    return new firebase.firestore.GeoPoint(lat, lng)
}
