
import {db, auth} from "./Firebase.js";
import firebase from "firebase"



const users = db.collection('users')


export async function get(id) {

    const snapshot = await users.doc(id).get();
    return snapshot
}
export async function getAsArray(id) {

    const snapshot = await users.doc(id).get();
    const data = snapshot.data()
    data.uid = id
    return data
}


export async function getAll() {

    const snapshot = await users.get();

    if (snapshot.empty) {
        console.log('No matching documents.');
        return;
    }

    snapshot.forEach(doc => {
        console.log(doc.id, '=>', doc.data());
    });
}

export async function create(user) {

    if ( !hasEmailAndPassword(user) ) {
        throw new Error('ユーザー登録に必要なemailとpasswordが不正です。')
    }
    // auth情報を新規作成
    const newAuth = await auth.createUserWithEmailAndPassword(user.email, user.password)
    // 作成したauth情報のuidを格納
    user.uid = newAuth.user.uid
    // パスワードは格納せず削除
    delete user.password
    // usersテーブルに格納
    return users.doc(user.uid).set(user);

}

export function hasEmailAndPassword(user) {

    return user.email && user.password

}

export async function update(id, data) {

    return await users.doc(id).update(data)

}

// auth関連

    export const signIn = async (email, psw) => {
    
        return await auth.signInWithEmailAndPassword(email, psw)
    
    }
    export const signOut = async (redirectTo = null) => {
    
        // 非同期でログアウト処理
        return await auth.signOut()
    
    }
    export const check = (fnuc) => {

        auth.onAuthStateChanged(fnuc)
    }
    export const makeCredential = (password) => {

        const credential = firebase.auth.EmailAuthProvider.credential(
            auth.currentUser.email,
            password
        )
        return credential
    }

    export const updatePassword = async (opass, npass) => {

        const credential = makeCredential(opass)

        await auth.currentUser.reauthenticateWithCredential(credential)
        return await auth.currentUser.updatePassword(npass)
    }

    export const updateEmail = async (opass, email) => {

        const credential = makeCredential(opass)

        await auth.currentUser.reauthenticateWithCredential(credential)
        return await auth.currentUser.updateEmail(email)
    }























