import React from 'react'
import * as G from "../../GlobalResources";


const LoadingScreen = (props) => {

    if (props.shown) {
        return (
            <div id={'loading-screen'} onClick={e => e.stopPropagation()}>
                <div className="inner">
                    <div className="loading-icon">
                        <div className={'icon'}>
                            <img src={G.IMG_URL('icons/loading.png')} />
                        </div>
                        <div className="text">
                            <img src={G.IMG_URL('icons/loading-text.png')} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return null
}

export default LoadingScreen