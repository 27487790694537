import React, {useEffect, useRef, useState} from 'react'
import * as G from "../../GlobalResources"
import { useHistory } from "react-router-dom";
import FcText from "../../components/atoms/FcText";


const MainVisual = (props) => {

    const history = useHistory()

    const [searchQuery, setSearchQuery] = useState('');
    const [bgImageIndex, setBgImageIndex] = useState(0);

    const bgImgRef = useRef(null)


    const goToCurrentLocation = async (e) => {

        history.push('/map')
    }
    const onSubmit = (e) => {
        e.preventDefault()
        history.push('/map?adr=' + searchQuery)

    }
    const handleInputChange = (e) => {
        setSearchQuery(e.value)
    }

    const renderBgImage = () => {

        return (
            <div className="bg">
                <div className={'wrapper'}>
                    <img ref={bgImgRef} className={'img-' + bgImageIndex} src={G.IMG_URL('mv-slides/' + bgImageIndex + '.jpg')} />
                </div>
            </div>
        )
    }



    useEffect(()=>{

        let counter = 3
        const intervalId = setInterval( (e) => {

            counter = (counter + 1) % 3
            setBgImageIndex(counter)

        }, 5000)

        return () => {
            clearInterval(intervalId)
        };

    }, [])


    return (
        <section id={'mv'}>

            {renderBgImage()}

            {/*<div className="filter"></div>*/}

            <div className="substance">
                <h1 className={'title'}>
                    <span>釣果の投稿・共有サービス</span>
                    <small>みんなの釣果をマップで検索</small>
                </h1>
                <form className={'control'} onSubmit={ e => { onSubmit(e) } }>
                    <div className="search">
                        <FcText
                            className={'input'}
                            value={searchQuery}
                            onChange={e => handleInputChange(e)}
                            placeholder={'地名・スポットなどで検索'}
                        />
                        <button className={'submit'}><span className="label">検索</span></button>
                    </div>
                    <div className={'current-location'}>
                        <button type="button" onClick={ e => goToCurrentLocation(e) }>
                            <i className={'icon'} />
                            <span className={'text'}>現在地周辺を検索</span>
                        </button>
                    </div>
                </form>
            </div>

        </section>
    )
}

export default MainVisual