import {storage} from './Firebase.js'
import * as ContributionService from "./ContributionService";

const resImg = storage.ref().child('result_imgs')
const tackleImg = storage.ref().child('tackle_imgs')
const userImg = storage.ref().child('user_imgs')

async function getFile(ref){

    try {
        return await ref.getDownloadURL()
    } catch (e) {
        return false
    }
}


export function uploadResultImg(file, name = '') {

    const fileName = name || file.name

    return resImg.child(fileName).put(file)
}

export function uploadTackleImgs(file, name = '') {

    const fileName = name || file.name
    return tackleImg.child(fileName).put(file)
}


export async function getContributionThumbnail(id) {

    const ref = resImg.child(id).child('1')
    const queue = []

    queue.push( ref.getDownloadURL() )
    queue.push( ref.getMetadata() )

    const res = await Promise.all(queue)

    return {
        url: res[0],
        meta: res[1],
    }
}


export async function putUserCover(id, file) {

    const ref = userImg.child(id).child('cover')
    await ref.put(file)

    return ref.getDownloadURL()
}

export async function putUserProfile(id, file) {


    const ref = userImg.child(id).child('profile')
    await ref.put(file)

    return ref.getDownloadURL()

}
export async function getUserCover(id) {

    const ref = userImg.child(id).child('cover')
    return await getFile( ref )
}
export async function getUserProfile(id) {

    const ref = userImg.child(id).child('profile')
    return await getFile( ref )
}




export async function downloadImgs(ref, id) {

    const list = await ref.child(id).listAll()

    const getUrlQueue = []
    const getMetaQueue = []

    list.items.map(i => {

        getUrlQueue.push( i.getDownloadURL() )
        getMetaQueue.push( i.getMetadata() )
    })
    const url = await Promise.all(getUrlQueue)
    const meta = await Promise.all(getMetaQueue)

    const data = []

    // 全てのユーザー情報が取得できたら上で用意したコメントの配列に格納
    for ( const [key, value] of url.entries() ) {

        data.push({
            url: url[key],
            meta: meta[key],
        })
    }

    return data
}

export async function downloadResultImgs(id) {

    return await downloadImgs(resImg, id)
}


export async function downloadTackleImgs(id) {

    return await downloadImgs(tackleImg, id)
}
