import React, {useEffect, useState} from 'react'
import * as G from "../../../GlobalResources";
import {Link, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import * as UserService from "../../../services/UserService";



const Header = (props) => {

    const history = useHistory()
    const [ userMenuOpen, setUserMenuOpen ] = useState(false)


    const onBackButtonClick = (e) => {

        history.goBack()

    }
    const signOut = async (e) => {

        setUserMenuOpen(false)
        await UserService.signOut()
        history.push('/')

    }
    const userMenu = () => {

        if (userMenuOpen) {
            return (
                <nav onClick={ e => e.stopPropagation() } className={'user-menu'}>
                    <ul className={'menu-list'}>

                        <li onClick={ e => {
                            setUserMenuOpen(false)
                            history.push('/user-edit')
                        } }>プロフィール編集</li>

                        <li onClick={ e => signOut(e) }>ログアウト</li>
                        <li onClick={ e => setUserMenuOpen(false) }>閉じる</li>
                    </ul>
                </nav>
            )
        }
    }

    const userIcon = () => {

        if (props.authInfo.isAuth) {

            const icon = props.authInfo.user.profile_url || G.IMG_URL('icons/user.png')


            return (
                <div className={'user-icon'}>
                    <div
                        onClick={ e => setUserMenuOpen(true) }
                        className="circle"
                        style={{backgroundImage: 'url(' + icon + ')',}}
                    />

                    {userMenu()}
                </div>
            )

        } else {
            return <Link to={'/sign-in'} className={'signin-button'}>ログイン</Link>

        }
    }


    return (
        <header id="header">
            <div className="inner">
                <div onClick={e => history.push('/')} className="logo">
                    <img src={G.IMG_URL('logo.png')} alt="つり吉 金太郎ロゴ"/>
                </div>
                <button onClick={ e => onBackButtonClick(e)} className={'back-button'} type="button">
                    <span className={'label'}>戻る</span>
                </button>
                {userIcon()}

            </div>
        </header>
    )
}

export default connect( state => state )( Header )