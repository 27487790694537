import React from 'react'



const AlertBox = (props) => {


    const onClose = (e) => {

        if (props.onClose){
            props.onClose(e)
        }
    }

    if (props.shown) {
        return (
            <div id={'alert'} onClick={e => onClose(e)}>
                <div className="inner" onClick={e => e.stopPropagation()}>
                    <div className={'header'}>{props.header || 'Notice'}</div>
                    <div className={'body'}>{props.body}</div>
                    <div className={'footer'}>
                        <button className={'close'} onClick={ e => onClose(e) }>
                            {props.close || 'OK'}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
    return null
}

export default AlertBox