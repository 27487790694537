import React from 'react'

import {GoogleMap, LoadScript } from "@react-google-maps/api";
import * as G from '../../GlobalResources'
import MapMarker from "../atoms/MapMarker";


export default class MapComponent extends React.Component{


    // マップを操作するための参照
    mapRef = null
    apiKey = 'AIzaSyARIrdxgRJUJw88Q9lHx5mKVocgQGfYc6w'


    constructor (props) {
        super(props)

        this.state = {

            center: null,
            defaultCenter: props.defaultCenter,
            markers: props.markers,
            options: props.options || G.defaultMapOptions,
        }
    }

    //
    componentDidMount() {
    }

    // 親コンポーネントよりpropsが更新された場合
    componentDidUpdate(prevProps) {

        if (this.props.defaultCenter !== prevProps.defaultCenter) {
            this.setState({
                defaultCenter: this.props.defaultCenter,
                markers: this.props.markers
            })
        }
    }

    onCenterChanged() {

        if(this.mapRef){

            this.setState({center: this.mapRef.getCenter().lat()})
            // console.log(this.state.center)

        }

        // 親コンポーネントでイベント制御
        if (this.props.onCenterChanged){
            this.props.onCenterChanged()
        }

    }

    onClick(e) {


        const data = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        }

        // 親コンポーネントでイベント制御
        if (this.props.onClick){
            this.props.onClick(e)
        }

    }

    onMapLoaded( ref ) {
        this.mapRef = ref


        // 親コンポーネントでイベント制御
        if (this.props.onLoad){
            this.props.onLoad(ref)
        }
    }



    render() {

        return (
            <>
                <LoadScript googleMapsApiKey={this.apiKey}>
                    <div id={this.props.id}>
                        <GoogleMap
                            onLoad={ ref => this.onMapLoaded(ref) }
                            options={this.state.options}
                            mapContainerStyle={{ width: "100%", height: "100%",}}
                            center={this.state.defaultCenter}
                            zoom={this.props.zoom || 17}
                            onClick={ (e) => this.onClick(e) }
                            onCenterChanged={() => this.onCenterChanged()}
                        >
                            <MapMarker places={this.state.markers} />
                        </GoogleMap>
                    </div>
                </LoadScript>
            </>

        );
    }
}
